import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;
    display: grid;
    justify-content: center;

    img {
         max-height: 440px;
    }

    ${({doubleImage}) => `${doubleImage && `
        @media ${breakpoint.sm} {
            grid-template-rows: 440px auto;
        }
    `}`}



    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }

   /*  img {
        max-height: 440px;
        width: auto;
    } */
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0;
    font-size: 0.875rem;
`

const BlogList = styled.ol `
     padding: 0;
     list-style: none;
    counter-reset: my-awesome-counter;

    h3 {
        font-size: 1rem;
        padding-left: 1.25em;
        margin-bottom: 0.5em;
        font-weight: 700;
    }
    p {
        display: inline;
    }

    li {
        margin: 1.5rem 0;
        line-height: 1.5;
        counter-increment: my-awesome-counter2;
        position: relative;

        &::before {
            content: counter(my-awesome-counter2) "." ;
            font-weight: 700;
            position: absolute;
        }

        ul li::before {
            content: normal;
        }
    }
`
const DoubleImage = styled.div `
    margin: 1.5rem 0 2.5rem;

    @media ${breakpoint.sm} {
        display: grid;
     gap: 0.5rem;
        grid-template-columns: repeat(2, 1fr);
    }
`

const Author = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  opacity: 0.75;
`

const Info = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin: 0.3rem 0 0.5rem;
  margin-bottom: 2rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>5 důvodů, proč navštívit italské Bergamo</Title>
            <Info>
                <Author>Simona</Author>
                <Date>6. 4. 2023</Date>
            </Info>
            <p>Bergamo je město na severu Itálie v oblasti Lombardie přibližně 50 km východně od o něco známějšího Milána, města módy. Že jste o Bergamu slyšeli, ale nikdy tam nebyli? Nebo dokonce vůbec neslyšeli?</p>
            <p>V dnešním příspěvku se s vámi podělím o pár důvodů, proč sem opakovaně ráda vracím.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/bergamo/bergamo1.jpeg" alt="Bergamo"></StaticImage>
            </StyledFigure>
            <p>Bergamo bylo první město, kam jsem ve svých 20 letech <b>poprvé letěla letadlem</b> na prodloužený víkend s partou přátel. Možná proto pro mě zůstává srdcovou záležitostí.</p>
                <BlogList>
                    <li>
                        <h3>Levné letenky a krátká cesta</h3>
                        <p>Při vhodném načasování je možné zpáteční letenky sehnat v rozmezí od&nbsp;500&nbsp;-&nbsp;1000&nbsp;Kč/osobu. Nám se to naposledy podařilo v únoru 2022. Koupily jsme  zpáteční letenky za <b>1&nbsp;115 Kč/2 osoby</b>.</p><br />
                        <p>Let z Prahy trvá přibližně 1&nbsp;hodinu a 20&nbsp;minut. A z letiště <a href="https://www.google.com/maps/place/Leti%C5%A1t%C4%9B+Orio+al+Serio/@45.6695744,9.668607,13z/data=!4m5!3m4!1s0x4781506f9e32830d:0x177c7faf1030082!8m2!3d45.6695707!4d9.7036313">Orio al Serio</a> se do města dostanete za dalších 20 minut linkou č.&nbsp;1, která vás doveze na autobusové nádraží, odkud je vše krásně v docházkové vzdálenosti.</p><br />
                        <p>Bergamo je i levnější variantou letiště v případě, kdy chcete strávit pár dní (nebo jednodenní výlet) v Miláně. Do Milana se z letiště dostanete autobusem č.&nbsp;141, který jezdí každých 30&nbsp;minut nebo linkou společnosti <a href="https://www.terravision.eu/airport_transfer/bus-bergamo-airport-milan/price-and-timetable-bergamo-airport-milan/">Terravision</a>.</p><br />
                        <p>Ubytování jsem vždy volila přes <a href="https://www.booking.com/">booking.com</a> v dolní části města (Città bassa).</p>
                    </li>
                    <li>
                        <h3>Città alta</h3>
                        <p>Horní město, které je s dolní částí města propojeno mnoha uličkami a lanovkou, kterou určitě alespoň na jednu cestu využijte. Jízdenka stála <b>1,3&nbsp;€</b>. V historické části města můžete strávit několik hodin touláním se v uličkách, ochutnávání místních dobrot a nebo jen užívání si výhledů na celé město.</p>
                        <DoubleImage>
                            <StyledFigure doubleImage={true}>
                                <StaticImage src="../assets/images/blog/bergamo/bergamo2.jpeg" alt="Lanovkou do Cittá alta"></StaticImage>
                                <StyledFigcaption>Lanovkou do Cittá alta</StyledFigcaption>
                            </StyledFigure>
                            <StyledFigure doubleImage={true}>
                                <StaticImage src="../assets/images/blog/bergamo/bergamo3.jpeg" alt="Torre Civica na Piazza Vechia"></StaticImage>
                                <StyledFigcaption>Torre Civica na Piazza Vechia, každý den ve 22hod. zní z věže 130 úderů zvonů, které v době minulé ohlašovaly noční uzavření všech městských hradeb</StyledFigcaption>
                            </StyledFigure>
                        </DoubleImage>
                    </li>
                    <li>
                        <h3>Italská zmrzlina</h3>
                        <p><i>La stracciatella il gelato di Bergamo</i>, pokud jste milovníky italského gelata (zmrzliny) a zároveň "stračatelové" příchuti, tak vám možná právě informace, že zmrzlina vznikla v tomhle městě udělá radost. A udá další důvod proč se sem vydat.</p> <br />
                        <p>Zmrzlina zde vznikla už v roce 1961, a to po mnohých pokusech o správnou kombinaci v rukou Enrica Panattoniho v podniku <a href="https://cosedibergamo.com/2017/09/19/la-stracciatella-il-gelato-di-bergamo/">La Marianna</a>, který do dnešních dní najdete v horním městě. Kromě zmrzliny doporučuji ochutnat i <b>Polenta e osei</b> - specialita Bergama, kterou tvoří sladká polenta pokrytá žlutou mandlovou pastou ozdobená čokoládovými nebo marcipánovými ptáčky.</p>
                        <DoubleImage>
                            <StyledFigure>
                                <StaticImage src="../assets/images/blog/bergamo/bergamo4.jpeg" alt="Il gelato de La Marianna"></StaticImage>
                                <StyledFigcaption>Il gelato de La Marianna</StyledFigcaption>
                            </StyledFigure>
                            <StyledFigure>
                                <StaticImage src="../assets/images/blog/bergamo/bergamo5.jpeg" alt="Polenta e osei"></StaticImage>
                                <StyledFigcaption>Polenta e osei</StyledFigcaption>
                            </StyledFigure>
                        </DoubleImage>
                    </li>
                    <li>
                        <h3>Italské dolce far niente</h3>
                        <p>Ihned se přizpůsobíte atmosféře a budete si užívat volného plynutí dne, zastavíte se tam a tady, ochutnáte pinsy, sednete si v parku s výhledem na město a budete se vyhřívat na sluníčku nebo si dáte v restauraci dobré jídlo a drink.</p>
                        <StyledFigure>
                            <StaticImage src="../assets/images/blog/bergamo/bergamo6.png" alt=""></StaticImage>
                        </StyledFigure>
                    </li>
                    <li>
                        <h3> Místa, která určitě neminete</h3>
                        <ul>
                            <li><i>Piazza Vechia</i> - hlavní náměstí historické části horního města s dominantním městským palácem Pallazo della Ragione a 43 metrů vysokou Torre del Campanone</li>
                            <li><i>Bazilika Santa Maria Maggiore</i> - jedna z hlavních památek historického Bergama</li>
                            <li><i>Duomo di Bergamo</i> - bergamská katedrála, méně nápadná oproti Bazilice, tak si je nespleťte</li>
                            <li><i>Pevnost Rocca</i> - z pevnosti se vám naskytne krásný výhled na celé město a Alpy v pozadí, v prostorech pevnosti se nachází "muzeum 18. století"</li>
                            <li><i>Porta Nuova</i> - na tuto vstupní bránu do města narazíte nejspíš nejdříve po svém příjezdu do města, naskytne se vám od ní nejkrásnější pohled na horní historickou část města</li>
                        </ul>
                        <DoubleImage>
                            <StyledFigure doubleImage={true}>
                                <StaticImage src="../assets/images/blog/bergamo/bergamo7.jpeg" alt="Pevnost Rocca"></StaticImage>
                                <StyledFigcaption>Pevnost Rocca</StyledFigcaption>
                            </StyledFigure>
                            <StyledFigure doubleImage={true}>
                                <StaticImage src="../assets/images/blog/bergamo/bergamo8.jpeg" alt="Bergamu se také říká město zvonů."></StaticImage>
                                <StyledFigcaption>Bergamu se také říká město zvonů.</StyledFigcaption>
                            </StyledFigure>
                        </DoubleImage>
                    </li>
                   <p>Pokud chcete někam vyrazit jen na prodloužený víkend a na chvíli opustit svůj každodenní stereotyp, rozptýlit se, užívat si dobré jídlo, pití a atmosféru, Bergamo rozhodně doporučuji. </p>
                </BlogList>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
